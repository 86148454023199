import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {Eye, EyeOff} from "lucide-react";
import {Switch} from "@/components/ui/switch";
import {useState} from "react";
import {useToast} from "@/components/ui/use-toast"
import {zodResolver} from "@hookform/resolvers/zod"
import {useForm} from "react-hook-form"
import AuthService from '../services/AuthService';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import * as z from "zod"
import { useNavigate } from "@tanstack/react-router";

const formSchema = z.object({
    username: z.string().min(2, {
        message: "Username must be at least 2 characters.",
    }),
    password: z.string(),
    rememberMe: z.boolean(),
})


export default function LoginForm() {
    const navigate = useNavigate();
    const {toast} = useToast()
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            password: "",
            rememberMe: false,
        },
    })

    async function onSubmit(data: z.infer<typeof formSchema>) {
        toast({
            description: (
                <pre>
                  <code>{JSON.stringify(data, null, 2)}</code>
                </pre>
            ),
        })
        
        await AuthService.login(data.username, data.password).then(() => {navigate({ to: '/' })});
    }

    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                    control={form.control}
                    name="username"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Email or username</FormLabel>
                            <FormControl>
                                <Input placeholder="Email or username" {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Password</FormLabel>
                            <Label className="flex flex-row">
                                <FormControl>
                                    <Input placeholder="Password" {...field}
                                           type={passwordVisible ? "text" : "password"}
                                           className="pr-12"
                                    />
                                </FormControl>
                                <Button
                                    className="bg-transparent hover:bg-[#F5F6F6]/50 text-[#000000] absolute flex items-center justify-center right-3"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    type="button"
                                >
                                    {passwordVisible ? <EyeOff/> : <Eye/>}
                                </Button>
                            </Label>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="rememberMe"
                    render={({field}) => (
                        <FormItem>
                            <div className="flex items-center space-x-2">
                                <FormControl>
                                    <Switch checked={field.value} onCheckedChange={field.onChange}/>
                                </FormControl>
                                <Label>Remember me</Label>
                            </div>
                        </FormItem>
                    )}
                />

                <Button className="w-full bg-[#000000] text-white rounded-md" type="submit">Login</Button>
            </form>
        </Form>
    )
}