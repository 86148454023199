import ApiService from './ApiService';

const AuthService = {
  login: async (email: string, password: string): Promise<void> => {
    const response = await ApiService.post('/users/jwt/create/', { email, password });
    const { access, refresh } = response.data;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
  },
  logout: (): Promise<void> => {
    return new Promise((resolve) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.log('Tokens removed from localStorage');
        resolve();
    });
  },
  getAccessToken: (): string | null => {
    return localStorage.getItem('access_token');
  },
  getRefreshToken: (): string | null => {
    return localStorage.getItem('refresh_token');
  },
  isAuthenticated: (): boolean => {
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken;
  }
};

export default AuthService;
