import React, { useState, useEffect } from 'react';
import UserService from '../services/UserService';
import DashboardSidebar from '@/components/sidebar';
import { LogoutButton } from '@/components/LogoutButton';

interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUser = await UserService.getUser();
      setUser(fetchedUser);
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  return (
    <div className="flex">
      <DashboardSidebar/>
      <LogoutButton/>
      {user && (
        <ul>
          <li>ID: {user.id}</li>
          <li>Email: {user.email}</li>
          <li>First Name: {user.first_name}</li>
          <li>Last Name: {user.last_name}</li>
        </ul>
      )}
    </div>
  );
};

export default Dashboard;
