import ApiService from './ApiService';
import { User, UserCreatePasswordRetype, Activation, SendEmailReset, UsernameResetConfirm, PasswordResetConfirmRetype, SetUsername, SetPassword } from '../types/User';

const UserService = {
  getUser: async (): Promise<User> => {
    const response = await ApiService.get('/auth/users/me');
    return response.data;
  },
  createUser: async (data: UserCreatePasswordRetype): Promise<UserCreatePasswordRetype> => {
    const response = await ApiService.post<UserCreatePasswordRetype>('/auth/users/', data);
    return response.data;
  },
  activateUser: async (data: Activation): Promise<Activation> => {
    const response = await ApiService.post<Activation>('/auth/users/activation/', data);
    return response.data;
  },
  resendActivation: async (data: SendEmailReset): Promise<SendEmailReset> => {
    const response = await ApiService.post<SendEmailReset>('/auth/users/resend_activation/', data);
    return response.data;
  },
  resetEmail: async (data: SendEmailReset): Promise<SendEmailReset> => {
    const response = await ApiService.post<SendEmailReset>('/auth/users/reset_email/', data);
    return response.data;
  },
  confirmResetEmail: async (data: UsernameResetConfirm): Promise<UsernameResetConfirm> => {
    const response = await ApiService.post<UsernameResetConfirm>('/auth/users/reset_email_confirm/', data);
    return response.data;
  },
  resetPassword: async (data: SendEmailReset): Promise<SendEmailReset> => {
    const response = await ApiService.post<SendEmailReset>('/auth/users/reset_password/', data);
    return response.data;
  },
  confirmResetPassword: async (data: PasswordResetConfirmRetype): Promise<PasswordResetConfirmRetype> => {
    const response = await ApiService.post<PasswordResetConfirmRetype>('/auth/users/reset_password_confirm/', data);
    return response.data;
  },
  setUsername: async (data: SetUsername): Promise<SetUsername> => {
    const response = await ApiService.post<SetUsername>('/auth/users/set_email/', data);
    return response.data;
  },
  setPassword: async (data: SetPassword): Promise<SetPassword> => {
    const response = await ApiService.post<SetPassword>('/auth/users/set_password/', data);
    return response.data;
  },
};

export default UserService;
