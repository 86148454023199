// src/services/ApiService.ts
import axios, { AxiosInstance } from 'axios';
import AuthService from './AuthService';
import { useNavigate } from '@tanstack/react-router';

const ApiService: AxiosInstance = axios.create({
  baseURL: process.env.BACKEND_URL,
  withCredentials: true,
});

ApiService.interceptors.request.use(
  async (config) => {
    const accessToken = AuthService.getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      AuthService.logout();
      const navigate = useNavigate();
      navigate({ to: '/login' });
    }
    return Promise.reject(error);
  }
);


export default ApiService;
