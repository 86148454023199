import React, {useState} from "react";
import {
    Calculator,
    ChevronLeft,
    FileSpreadsheet,
    Goal,
    LayoutDashboard,
    LogOut,
    Menu,
    Settings,
    Shapes,
    Siren,
    SunMoon,
    Wallet
} from 'lucide-react';
import {CapybaraLogo} from "@/components/logo";
import {Label} from "@/components/ui/label";
import {Button} from "@/components/ui/button";
import { Link, useNavigate } from "@tanstack/react-router";
import AuthService from "@/services/AuthService";


const styleActiveButton = "dark:bg-[hsl(var(--secondary))] bg-[hsl(var(--secondary))]  text-[hsl(var(--primary))]"
const styleInactiveButton = "dark-hover:bg-[hsl(var(--secondary))] hover:bg-stone-200 hover:text-[hsl(var(--primary))] text-gray-500 active:bg-gray-300"
const stylePagesButton = "w-full flex items-center justify-left space-x-2 py-2 px-2 rounded-lg"
const stylePagesLogo = "ml-3 mr-2 w-5 h-5"
const styleOptionsButton = "w-full flex items-center justify-center space-x-2 py-3 rounded-lg"
const styleOptionsLogo = "w-5 h-5"


type SidebarItemProps = {
    name: string;
    route: string;
    icon: React.ElementType;
    isActive?: boolean;
    onClick?: () => void;
}


const SidebarItem = (props: SidebarItemProps): SidebarItemProps => {
    return {
        name: props.name,
        route: props.route,
        icon: props.icon,
    }
}


const SidebarPagesButton: React.FC<SidebarItemProps> = ({
                                                            isActive = false,
                                                            icon,
                                                            name,
                                                            route,
                                                        }) => {
    const Icon = icon as React.ElementType;
    const iconElement = <Icon className={stylePagesLogo} alt={name}/>;
    return (
        <a href={route}
              className={isActive ? `${stylePagesButton} ${styleActiveButton}` : `${stylePagesButton} ${styleInactiveButton}`}>
            {iconElement}
            <Label className="text-sm font-medium dark:white">{name}</Label>
        </a>
    )
};

const SidebarOptionsButton: React.FC<SidebarItemProps> = ({
                                                              isActive = false,
                                                              icon,
                                                              name,
                                                              route,
                                                              onClick
                                                          }) => {
    const Icon = icon as React.ElementType;
    const iconElement = <Icon className={styleOptionsLogo} alt={name}/>;
    
    if (onClick) {
        return (
            <a href={route}
                  onClick={onClick}
                  className={isActive ? `${styleOptionsButton} ${styleActiveButton}` : `${styleOptionsButton} ${styleInactiveButton}`}>
                {iconElement}
            </a>
        )
    } else {
        return (
            <a href={route}
                  className={isActive ? `${styleOptionsButton} ${styleActiveButton}` : `${styleOptionsButton} ${styleInactiveButton}`}>
                {iconElement}
            </a>
        )
    }
}

export default function DashboardSidebar() {
    const [isSidebarOpen, setSidebarOpen] = useState(false); // Added state to manage sidebar
    const pathname = "";

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };


    const SidebarPages = (): Array<SidebarItemProps> => {
        return [
            SidebarItem({
                name: "Dashboard",
                route: "/dashboard",
                icon: LayoutDashboard,
            }),
            SidebarItem({
                name: "Transactions",
                route: "/dashboard/transactions",
                icon: Wallet,
            }),
            SidebarItem({
                name: "Budgets",
                route: "/dashboard/budgets",
                icon: Calculator,
            }),
            SidebarItem({
                name: "Reports",
                route: "/dashboard/reports",
                icon: FileSpreadsheet,
            }),
            SidebarItem({
                name: "Goals",
                route: "/dashboard/goals",
                icon: Goal,
            }),
            SidebarItem({
                name: "Alerts",
                route: "/dashboard/alerts",
                icon: Siren,
            }),
            SidebarItem({
                name: "Categories",
                route: "/dashboard/categories",
                icon: Shapes,
            })
        ]
    }

    const SidebarOptions = (): Array<SidebarItemProps> => {
        const navigate = useNavigate();
        const themeToggleItem = {
            name: "Toggle Theme",
            route: "#",
            icon: SunMoon,
            onClick: () => {}
        };
        const handleLogout = async () => {
            try {
                await AuthService.logout();
                console.log('Logged out successfully');
            } catch (error) {
                console.error('Error during logout:', error);
            } finally {
                navigate({ to: '/login' });
            }
        };

        return [
            SidebarItem({
                name: "Settings",
                route: "/dashboard/settings",
                icon: Settings,
            }),
            themeToggleItem,
            SidebarItem({
                name: "Logout",
                route: "/login",
                icon: LogOut,
                onClick: handleLogout,
            })
        ]
    }

    return (
        <>
            <Button onClick={toggleSidebar} className="m-4 md:hidden" variant="outline">
                <Menu/>
            </Button>
            <aside
                className={`top-0 ${isSidebarOpen ? 'left-0' : '-left-full'} z-50 h-full p-4 flex flex-col bg-stone-100 dark:bg-stone-950 dark:text-gray-200 md:left-0 md:w-56 transition-all duration-300`}>
                <Button onClick={toggleSidebar} className="mb-2 md:hidden">
                    <ChevronLeft/>
                </Button>
                <div className="flex items-center mb-4 space-x-1">
                    <Link href={"/"} className="w-48 h-16">
                        <CapybaraLogo/>
                    </Link>
                </div>
                <nav className="flex flex-col justify-between h-full space-y-2">
                    <div className="space-y-2">
                        {SidebarPages().map((option) => (
                            <SidebarPagesButton
                                key={option.name}
                                isActive={option.route === pathname}
                                icon={option.icon}
                                name={option.name}
                                route={option.route}
                            />
                        ))}
                    </div>
                    <div className="flex flex-row items-center justify-center h-6 space-x-4 rounded-lg">
                        {SidebarOptions().map((option) => (
                            <SidebarOptionsButton
                                key={option.name}
                                isActive={option.route === pathname}
                                icon={option.icon}
                                name={option.name}
                                route={option.route}
                                onClick={option.onClick}
                            />
                        ))}
                    </div>
                </nav>
            </aside>
        </>
    )
}