import React, { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';
import AuthService from '../services/AuthService';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      navigate({ to: '/login' });
    }
  }, [navigate]);

  return AuthService.isAuthenticated() ? <>{children}</> : null;
};

export default ProtectedRoute;
