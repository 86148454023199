import React from 'react';
import {CardHeader, CardContent, Card} from "@/components/ui/card"
import {Label} from "@/components/ui/label"
import {CapybaraLogo} from "@/components/logo";
import {Separator} from "@/components/ui/separator";
import { Link } from "@tanstack/react-router";
import LoginForm from '@/components/login_form';

const Login: React.FC = () => {
  return (
      <div className="fixed inset-0 flex items-center justify-center bg-[#FAFAFA]">
          <Card
              className={`mx-auto max-w-sm bg-[#FEFEFE] rounded-lg shadow-md transition-all duration-500 ease-in-out transform scale-100`}
          >
              <CardHeader className="space-y-1 p-4 border-b border-[#DADBDE] text-center">
                  <CapybaraLogo/>
              </CardHeader>
              <CardContent className="rounded-b-lg p-4 bg-[#F5F6F6] space-y-4">
                  <LoginForm/>
                  <Link className="inline-block w-full text-center text-sm underline text-[#000000]"
                          href="#">
                      Forgot your password?
                  </Link>
                  <Separator/>
                  <Label className="flex flex-col space-y-2 text-center">
                      <Label className="text-[#333333]">
                          {"Don't have an account?"}
                      </Label>
                      <Link href="#" className="w-full text-sm underline text-[#000000]">
                          Request access to Beta
                      </Link>
                  </Label>
              </CardContent>
          </Card>
      </div>
  );
};

export default Login;
