import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux';
import { store as ReduxStore } from './store';
import { MantineProvider } from '@mantine/core'
import { createRouter, Route, RootRoute, Outlet, RouterProvider } from '@tanstack/react-router';

import Login from './routes/Login';
import Dashboard from './routes/Dashboard';
import ProtectedRoute from './routes/ProtectedRoute';

import "./App.css"

const rootRoute = new RootRoute({
  component: () => (
    <Outlet />
  ),
});

const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/login',
  component: Login,
});

const dashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => (
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  ),
});

const routeTree = rootRoute.addChildren([loginRoute, dashboardRoute]);
const router = createRouter({ routeTree });

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider store={ReduxStore}>
    <MantineProvider>
      <RouterProvider router={router}/>
    </MantineProvider>
            
    </ReduxProvider>
  </React.StrictMode>
);