import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@tanstack/react-router';
import { logout } from '../store/authSlice';
import { AppDispatch } from '../store';

export const LogoutButton: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await dispatch(logout());
    navigate({ to: '/login' });
  };

  return <button onClick={handleLogout}>Logout !</button>;
};